import React, { useState } from "react";

import Button from "@material-ui/core/Button";

import InputStyled from "../ComponentesExtras/Input";
//import AdsGoogle from "../../AdsGoogle";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import axios from "axios";
import getSearch from "../ListaCursos/Sections/GetSeach";
import Search from "@material-ui/icons/Search";
import CardsWithJobs from "./CardsWithJobs";
import shuffle from "../../utils/shuffle";

import {
  headerColor,
  whiteColor,
  grayColor,
  hexToRgb,
  dangerColor,
} from "../../assets/jss/material-kit-pro-react";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: grayColor[0],
    padding: theme.spacing(8, 0, 6),
  },
  textHeroContent: {
    color: whiteColor,
    fontSize: "105%",
    fontWeight: "bold",
  },
  textHeroContentDestaque: {
    color: headerColor,
  },
  heroTittle: {
    color: whiteColor,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "450px",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: grayColor[3],
    color: whiteColor,
  },
  footer: {
    backgroundColor: grayColor,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco,
  },
  fundoContenedorGrids: {
    backgroundColor: grayColor,
  },

  ColorMenu: {
    backgroundColor: headerColor,
    color: whiteColor,
  },

  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%",
  },
  Ads: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "none",
    textAlign: "center",
  },
  menuText: {
    marginTop: "10px",
    fontSize: "18px",
    textTransform: "none",
  },
  noEmail: {
    color: grayColor[3],
    borderColor: grayColor[3],
    marginRight: "10px",

    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5],
    },
    inscritos: {
      fontSize: "20px",
    },
  },
  inscribirme: {
    marginTop: "10px",

    color: headerColor,
    borderColor: headerColor,
    "&:hover,&:focus": {
      color: whiteColor,
      borderColor: whiteColor,
    },
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },

  containerLoad: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  containerInput: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  botonBuscar: {
    width: "150px",
    backgroundColor: dangerColor[0],
    borderColor: dangerColor[0],

    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: dangerColor[0],
      backgroundColor: dangerColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)",
    },
  },
  notFound: {
    padding: "20px",
  },
  listAlert: {
    padding: "20px",
    width: "290px",
  },
}));
export default function JobsContainer() {
  const classes = useStyles();

  const [jobs, setJobs] = useState([]);

  const [input, setInput] = useState("");
  const [location, setLocation] = useState("");
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const handleSearchJobs = async () => {
    setLoading(true);
    const url = `https://api.hmcursos.com/api/jobs`;
    if (input !== "" || location !== "") {
      const solve = await axios.get(url, {
        params: { search: input, location: location },
      });
      if (solve.data.length === 0) {
        setNotFound(true);
      }
      const random = shuffle(solve.data);
      setJobs(random);
    } else {
      setJobs([]);
      setEmpty(true);
    }

    setLoading(false);
  };

  const handleSearch = (value) => {
    setJobs([]);
    setLoading(false);
    setInput(getSearch(value));
    setEmpty(false);
    setNotFound(false);
  };

  const handleLocation = (value) => {
    setJobs([]);
    setLoading(false);
    setLocation(getSearch(value));
    setEmpty(false);
    setNotFound(false);
  };

  return (
    <>
      <Helmet>
        <title>Job Search </title>
      </Helmet>

      <main className={classes.fundoContenedorGrids}>
        <div>
          {/*HEADER DOWN*/}
          <Container maxWidth="md">
            <br></br>

            <div className={classes.containerInput}>
              <Typography
                align="left"
                variant="h6"
                style={{ textTransform: "initial" }}
              >
                Search Jobs Offers
              </Typography>

              <div>
                <p style={{ marginTop: "10px" }}>
                  Job title, industry or company:
                </p>
                <InputStyled
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Developer, Administration..."
                />
              </div>
              <div style={{ marginTop: "-5px" }}>
                <p>Location: </p>
                <InputStyled
                  onChange={(e) => handleLocation(e.target.value)}
                  placeholder="Belfast, Sheffield, Kent..."
                />
              </div>

              {empty ? (
                <p style={{ color: dangerColor[0] }}>
                  Please, write what you are looking for.
                </p>
              ) : null}

              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <Button
                  variant="outlined"
                  className={classes.botonBuscar}
                  onClick={handleSearchJobs}
                  disabled={loading}
                >
                  <Search style={{ marginRight: "5px" }} />
                  Search
                </Button>
              )}
            </div>
            {notFound ? (
              <div className={classes.notFound}>
                <h4 style={{ textAlign: "center" }}>
                  We didn´t find any results...
                </h4>
                <li>Try again with another keyword.</li>
                <li>Check if you wrote the keyword correctly.</li>
                <li>If the problem persists, try again later.</li>
              </div>
            ) : null}
          </Container>
        </div>
        {/*----------------------- CARDS ----------------------*/}

        <Container className={classes.cardGrid} maxWidth="md">
          {jobs.length > 0 && (
            <CardsWithJobs jobs={jobs} search={input} location={location} />
          )}
        </Container>
      </main>
    </>
  );
}
