import styled from "styled-components";
export const InputStyled = styled.div`
  display: flex;
  align-items: center;

  background: #f5f5f5;
  border-radius: 6px;
  margin: 10px 0 20px 0;

  padding: 10px;
  width: 250px;
  color: #666360;
  border: 1px solid #000000;

  input {
    background: transparent;
    color: #000000;
    flex: 1;
    border: 0;
    font-size: 15px;

    &::placeholder {
      color: #666360;
    }
  }
`;
