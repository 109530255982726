import React, { useState, useEffect, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Share from "@material-ui/icons/Share";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Schedule from "@material-ui/icons/Schedule";
import TrendingUp from "@material-ui/icons/TrendingUp";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import cardBlog1 from "assets/img/cursillo.jpg";
import cardBlog2 from "assets/img/examples/paro.jpg";

import blog8 from "assets/img/trampilla.jpg";
import higor from "assets/img/faces/higor.jpg";
import isabel from "assets/img/faces/isabel.jpg";
import hombre from "assets/img/faces/hombre.jpg";

import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";

const useStyles = makeStyles(styles);

export default function SectionCards() {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const elementRef = useRef();
  useEffect(() => {
    const onChange = (entries, observer) => {
      const el = entries[0];
      //console.log(el);
      if (el.isIntersecting) {
        setShow(true);

        observer.disconnect();
      }
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: "-100px",
    });
    observer.observe(elementRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div ref={elementRef}>
      {show ? (
        <div className="cd-section" id="cards">
          <div className={classes.sectionGray}>
            <div>
              {/* BLOG CARDS START */}
              <div>
                <div className={classes.container}>
                  <div className={classes.title}></div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <Card blog>
                        <CardHeader image>
                          <a
                            href="https://hmnoticias.com/cursos/"
                            target="_blankt"
                            rel="noreferrer noopener"
                          >
                            <img src={cardBlog1} alt="cursos" />
                            <div className={classes.cardTitleAbsolute}>
                              Cursos Gratuitos del INEM
                            </div>
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${cardBlog1})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody>
                          <Info>
                            <h6 className={classes.cardCategory}>IMPORTANTE</h6>
                          </Info>
                          <div className={classes.cardDescription}>
                            El Gobierno en conjunto con Amazon, Huawei y Google,
                            disponibilizan varios cursos gratuitos (online) con
                            empleo asegurado.
                          </div>
                        </CardBody>
                      </Card>
                      <Card color="info">
                        <CardBody color>
                          <h5 className={classes.cardCategorySocialWhite}>
                            <i className="fab fa-twitter" />
                            Twitter
                          </h5>
                          <h4 className={classes.cardTitleWhite}>
                            {'"'}Tú mayor error será no haber empezado hoy.{'"'}
                          </h4>
                        </CardBody>
                        <CardFooter>
                          <div className={classes.authorWhite}>
                            <img
                              src={higor}
                              alt="HigorRocha"
                              className={
                                classes.imgRaised + " " + classes.avatar
                              }
                            />
                            <span>Higor Rocha</span>
                          </div>
                          <div
                            className={
                              classes.statsWhite + " " + classes.mlAuto
                            }
                          >
                            <Favorite />
                            12.7K ·
                            <Share />
                            139
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <Card>
                        <CardBody>
                          <Danger>
                            <h6 className={classes.cardCategory}>
                              <TrendingUp /> TRENDING
                            </h6>
                          </Danger>
                          <h4 className={classes.cardTitle}>
                            <a
                              href="https://claseflix.com/blog/los-empleos-que-ofrece-google-con-sus-cursos-gratuitos"
                              target="_blankt"
                              rel="noreferrer noopener"
                            >
                              Los empleos que ofrece Google con sus cursos gratuitos
                            </a>
                          </h4>
                        </CardBody>
                        <CardFooter>
                          <div className={classes.author}>
                            <img
                              src={isabel}
                              alt="IsabelRocha"
                              className={
                                classes.imgRaised + " " + classes.avatar
                              }
                            />
                            <span>Isabel Rocha</span>
                          </div>
                          <div className={classes.stats + " " + classes.mlAuto}>
                            <Favorite />
                            347 ·
                            <ChatBubble />
                            45
                          </div>
                        </CardFooter>
                      </Card>
                      <Card blog>
                        <CardHeader image>
                          <a
                            href="https://claseflix.com/blog/cursos-gratuitos-de-como-vender-por-internet"
                            target="_blankt"
                            rel="noreferrer noopener"
                          >
                            <img src={cardBlog2} alt="paro" />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${cardBlog2})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody>
                          <Success>
                            <h6 className={classes.cardCategory}>Favorito</h6>
                          </Success>
                          <h4 className={classes.cardTitle}>
                            <a
                              href="https://claseflix.com/blog/cursos-gratuitos-de-como-vender-por-internet"
                              target="_blankt"
                              rel="noreferrer noopener"
                            >
                              Cursos Gratuitos de cómo vender por internet
                            </a>
                          </h4>
                          <p className={classes.cardDescription}>
                            Hoy en día vender por internet se ha vuelto una de las mejores formas de generar ingresos. Sin embargo, muchas personas creen erróneamente que solo se trata de publicar algunos productos y esperar que se vendan.
                          </p>
                        </CardBody>
                        <CardFooter>
                          <div className={classes.author}>
                            <img
                              src={hombre}
                              alt="dinero"
                              className={
                                classes.imgRaised + " " + classes.avatar
                              }
                            />
                            <span>Miguel Silva</span>
                          </div>
                          <div className={classes.stats + " " + classes.mlAuto}>
                            <Schedule />5 min
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <Card blog>
                        <CardHeader image>
                          <a
                            href="https://claseflix.com/listado-de-trabajos"
                            target="_blankt"
                            rel="noreferrer noopener"
                          >
                            <img src={blog8} alt="ayuda" />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${blog8})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody>
                          <Danger>
                            <h6 className={classes.cardCategory}>
                              <TrendingUp /> TRENDING
                            </h6>
                          </Danger>
                          <h4 className={classes.cardTitle}>
                            <a
                              href="https://claseflix.com/listado-de-trabajos"
                              target="_blankt"
                              rel="noreferrer noopener"
                            >
                              Estos son los trabajos con más ofertas de empleo
                              en el mundo...{" "}
                            </a>
                          </h4>
                        </CardBody>
                        <CardFooter>
                          <div className={classes.author}>
                            <img
                              src={hombre}
                              alt="miguel"
                              className={
                                classes.imgRaised + " " + classes.avatar
                              }
                            />
                            <span>Miguel Silva</span>
                          </div>
                          <div className={classes.stats + " " + classes.mlAuto}>
                            <Schedule />7 min
                          </div>
                        </CardFooter>
                      </Card>
                      <Card color="success">
                        <CardBody color>
                          <h5 className={classes.cardCategorySocialWhite}>
                            <NotificationImportantIcon /> Destacado
                          </h5>
                          <h4 className={classes.cardTitleWhite}>
                            <a
                              target="_blankt"
                              rel="noreferrer noopener"
                              href="https://hmnoticias.com/espana/como-saber-cuanto-paro-me-queda-o-si-tengo-derecho-a-paro/"
                            >
                              Cómo calcular el paro
                            </a>
                          </h4>
                          <p className={classes.cardDescriptionWhite}>
                            Descubre la cantidad de paro que te corresponde y
                            cómo pedirlo sin salir de la comodidad de tu casa de
                            manera rápida y sencilla.
                          </p>
                          <div
                            className={
                              classes.stats + " " + classes.justifyContentCenter
                            }
                          >
                            <a
                              target="_blankt"
                              rel="noreferrer noopener"
                              href="https://hmnoticias.com/espana/como-saber-cuanto-paro-me-queda-o-si-tengo-derecho-a-paro/"
                            >
                              <Button color="white" round>
                                Leer Ahora
                              </Button>
                            </a>
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
              {/* BLOG CARDS END */}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
