import React, { useState, useEffect, useCallback } from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import axios from "axios";

import CardsWithJobs from "./CardsWithJobs";

import {
  headerColor,
  whiteColor,
  grayColor,
  hexToRgb,
  dangerColor,
} from "../../assets/jss/material-kit-pro-react";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: grayColor[0],
    padding: theme.spacing(8, 0, 6),
  },
  textHeroContent: {
    color: whiteColor,
    fontSize: "105%",
    fontWeight: "bold",
  },
  textHeroContentDestaque: {
    color: headerColor,
  },
  heroTittle: {
    color: whiteColor,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "450px",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: grayColor[3],
    color: whiteColor,
  },
  footer: {
    backgroundColor: grayColor,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco,
  },
  fundoContenedorGrids: {
    backgroundColor: grayColor,
  },

  ColorMenu: {
    backgroundColor: headerColor,
    color: whiteColor,
  },

  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%",
  },
  Ads: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "none",
    textAlign: "center",
  },
  menuText: {
    marginTop: "10px",
    fontSize: "18px",
    textTransform: "none",
  },
  noEmail: {
    color: grayColor[3],
    borderColor: grayColor[3],
    marginRight: "10px",

    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5],
    },
    inscritos: {
      fontSize: "20px",
    },
  },
  inscribirme: {
    marginTop: "10px",

    color: headerColor,
    borderColor: headerColor,
    "&:hover,&:focus": {
      color: whiteColor,
      borderColor: whiteColor,
    },
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },

  containerLoad: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  containerInput: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  botonBuscar: {
    width: "150px",
    backgroundColor: dangerColor[0],
    borderColor: dangerColor[0],

    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: dangerColor[0],
      backgroundColor: dangerColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)",
    },
  },
  notFound: {
    padding: "20px",
  },
  listAlert: {
    padding: "20px",
    width: "290px",
  },
}));
export default function JobsContainer({ company }) {
  const classes = useStyles();

  const [jobs, setJobs] = useState([]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const loadJobs = useCallback(async () => {
    const url = `https://api.hmcursos.com/api/jobs`;

    const solve = await axios.get(url, {
      params: { search: company, location: "" },
    });

    setJobs(solve.data);
  }, [company]);

  useEffect(() => {
    loadJobs();
  }, [loadJobs]);

  return (
    <>
      <Helmet>
        <title>Job Offers - {capitalizeFirstLetter(company)} </title>
      </Helmet>

      <main className={classes.fundoContenedorGrids}>
        <div>
          {/*HEADER DOWN*/}
          <Container maxWidth="md">
            <br></br>

            <div className={classes.containerInput}>
              <Typography
                align="left"
                variant="h6"
                style={{ textTransform: "initial" }}
              >
                Here are the most recent {capitalizeFirstLetter(company)} job
                offers you might like. Don´t waste your time and take a look
                now...
              </Typography>
            </div>
          </Container>
        </div>
        {/*----------------------- CARDS ----------------------*/}

        <Container className={classes.cardGrid} maxWidth="md">
          {jobs.length > 0 ? (
            <CardsWithJobs jobs={jobs} />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          )}
        </Container>
      </main>
    </>
  );
}
