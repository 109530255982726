import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";

import { whiteColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(1),
    color: whiteColor,
    fontWeight: "bold",
    padding: "3px",
  },

  teste: {
    color: whiteColor,
    marginTop: "-3px",
    marginRight: "3px",
  },
}));

const EstiloInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    marginBottom: "3%",
    border: "1px solid #ced4da",
    fontSize: 18,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(255,255,255,.25)",
    },
  },
}))(InputBase);

export default function CategoriaSelect({ search, setSearch }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Select
        className={classes.selectEmpty}
        value={search}
        native
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        input={<EstiloInput />}
        inputProps={{
          classes: {
            icon: classes.teste,
          },
        }}
      >
        <option value="">Elige una categoría</option>
        <option value="administracion">Administración</option>
        <option value="marketing">Marketing</option>
        <option value="negocios">Negocios</option>
        <option value="musica">Música</option>
        <option value="programacion">Programación</option>
        <option value="salud">Salud</option>
        <option value="diseño">Diseño</option>
        <option value="idiomas">Idiomas</option>
        <option value="ingenieria">Ingeniería</option>
        <option value="informatica">Informática</option>
        <option value="finanzas">Finanzas</option>
        <option value="tecnico">Técnico</option>
      </Select>
    </div>
  );
}
