import React from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import a from "./images/a.jpeg";
import b from "./images/b.jpg";
import c from "./images/c.jpg";
import d from "./images/d.jpg";
import e from "./images/e.jpg";
import f from "./images/f.jpg";
import g from "./images/g.jpeg";
import h from "./images/h.jpg";
import i from "./images/i.jpeg";
import j from "./images/j.jpeg";
import k from "./images/k.jpg";
import l from "./images/l.png";
import m from "./images/m.jpg";
import n from "./images/n.jpeg";
import o from "./images/o.jpg";
import p from "./images/p.jpg";
import q from "./images/q.jpg";
import r from "./images/r.jpg";
import t from "./images/t.jpg";
import w from "./images/w.jpg";
import y from "./images/y.jpg";
import z from "./images/z.jpg";

import aa from "./images/aa.jpg";
import bb from "./images/bb.jpg";
import cc from "./images/cc.jpg";
import dd from "./images/dd.jpg";
import ee from "./images/ee.jpg";
import ff from "./images/ff.jpg";
import gg from "./images/gg.jpg";
import hh from "./images/hh.jpg";
import ii from "./images/ii.jpg";
import jj from "./images/jj.jpg";
import kk from "./images/kk.jpg";
import ll from "./images/ll.jpg";
import mm from "./images/mm.jpg";
import nn from "./images/nn.jpg";
import oo from "./images/oo.jpg";
import pp from "./images/pp.jpg";
import qq from "./images/qq.jpg";
import rr from "./images/rr.jpg";
import tt from "./images/tt.jpg";
import ww from "./images/ww.jpg";
import yy from "./images/yy.jpg";
import zz from "./images/zz.jpg";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 30,
    height: 30,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function BadgeAvatars() {
  const classes = useStyles();

  const ind = {
    0: a,
    1: b,
    2: c,
    3: d,
    4: e,
    5: f,
    6: g,
    7: h,
    8: i,
    9: j,
    10: k,
    11: l,
    12: m,
    13: n,
    14: o,
    15: p,
    16: q,
    17: r,
    18: t,
    19: w,
    20: y,
    21: z,
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    31: "",
    32: "",
    33: "",
    34: "",
  };
  const ind2 = {
    0: aa,
    1: bb,
    2: cc,
    3: dd,
    4: ee,
    5: ff,
    6: gg,
    7: hh,
    8: ii,
    9: jj,
    10: kk,
    11: ll,
    12: mm,
    13: nn,
    14: oo,
    15: pp,
    16: qq,
    17: rr,
    18: tt,
    19: ww,
    20: yy,
    21: zz,
  };

  const urlImg = Math.round(Math.random() * 34);
  const urlImg2 = Math.round(Math.random() * 20 + 1);
  const urlImg3 = Math.round(Math.random() * 21);
  const urlImg4 = Math.round(Math.random() * 21);

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
      >
        <AvatarGroup max={4}>
          <SmallAvatar alt="Reme" src={ind[urlImg]} />
          <SmallAvatar alt="Travis Howard" src={ind2[urlImg2]} />
          <SmallAvatar alt="Pepe" src={ind[urlImg3]} />

          <SmallAvatar
            alt="Lola"
            className={classes.SmallAv}
            src={ind2[urlImg4]}
          />
        </AvatarGroup>
      </StyledBadge>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      ></Badge>
    </div>
  );
}
