import React from "react";

export default class Ads extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  render() {
    const { block = 1 } = this.props;
    return (
      <div>
        {block === 1 && (
          <>
            {/*-- HM CURSOS TAG  --*/}
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-6362786189356343"
              data-ad-slot="2322908212"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </>
        )}
        {block === 2 && (
          <>
            {/* HM CURSOS BLOCO 2 */}
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-6362786189356343"
              data-ad-slot="5219476708"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </>
        )}
      </div>
    );
  }
}
