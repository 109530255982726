import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  texto1: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <h2 className={classes2.top}>Contactar</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div>
            <p className={classes2.texto}>
              Puedes contactar con nosotros siempre que necesites a través del
              e-mail ahurocha@gmail.com.
            </p>
            <p className={classes2.texto}>
              Haré lo posible para atender a tu petición lo antes posible.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
