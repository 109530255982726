import React, { useState } from "react";

import Context from "./Context";

const State = (props) => {
  const [i, setI] = useState(true);
  const [search, setSearch] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [header, setHeader] = useState("");
  const [error, setError] = useState(false);
  const [publicado, setPublicado] = useState(false);

  const [ofertas, setOfertas] = useState([]);
  const [aleatorio, setAleatorio] = useState(0);
  const [cargando, setCargando] = useState(true);
  const [pass, setPass] = useState("");
  const [provinciaSelect, setProvinciaSelect] = useState("");
  const [subir, setSubir] = useState({
    anunciante: "",
    localidad: "Seleccione una provincia",
    telefono: "",
    titulo: "",
    publicado: "Felipe y Hugo",
    fecha: "",
    descripcion: "",
    categoria: "Directo",
    precio: "",
  });

  return (
    <Context.Provider
      value={{
        search,
        setSearch,
        header,
        setHeader,
        empresa,
        setEmpresa,
        ofertas,
        setOfertas,
        aleatorio,
        setAleatorio,
        cargando,
        setCargando,
        subir,
        setSubir,
        pass,
        setPass,
        provinciaSelect,
        setProvinciaSelect,
        i,
        setI,
        error,
        setError,
        publicado,
        setPublicado,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default State;
