import React from "react";
import { InputStyled } from "./styles";

const Input = ({ placeholder, ...rest }) => {
  return (
    <InputStyled>
      <input placeholder={placeholder} {...rest} />
    </InputStyled>
  );
};

export default Input;
