import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
// pages for this product

import LandingPage from "views/LandingPage/LandingPage.js";
import Politicas from "views/Politicas/Politicas.js";
import AvisoLegal from "views/AvisoLegal/AvisoLegal.js";
import State from "./Context/State";
import Derechos from "views/Derechos/Derechos";
import Contactar from "views/Contactar/Contactar";
import Blog from "./views/Blog";
import ListaCursos from "./views/ListaCursos";
import Jobs from "./views/Jobs/Jobs";
import Companies from "./views/Companies/Companies";
import Offers from "./views/Offers/Jobs";

export default function App() {
  return (
    <>
      <State>
        <Router>
          <Switch>
            <Route exact path="/" component={Blog} />
            <Route exact path="/buscar" component={LandingPage} />
            <Route exact path="/cursos/:busqueda" component={ListaCursos} />

            <Route exact path="/contactar" component={Contactar} />

            <Route
              exact
              path="/politicas-de-privacidad"
              component={Politicas}
            />
            <Route exact path="/aviso-legal" component={AvisoLegal} />
            <Route exact path="/derechos" component={Derechos} />
            <Route exact path="/jobs" component={Jobs} />
            <Route exact path="/offers" component={Offers} />
            <Route exact path="/company/:name" component={Companies} />
          </Switch>
        </Router>
      </State>
    </>
  );
}
