import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>Políticas de Privacidad</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={(classes2.texto, classes2.contenedor)}>
              Queremos que comprenda cómo manejamos sus datos y también queremos
              que conozca sus derechos y opciones.
            </p>
            <p className={classes2.texto}>
              El tratamiento de tus datos se describen a continuación:
            </p>
            <h3 className={classes2.titulo}>
              ¿Qué información recopilamos sobre usted?
            </h3>
            <p className={classes2.texto}>
              La información que recopilamos sobre ti depende de la sección de
              nuestra web en la que te encuentres.
            </p>
            <p className={classes2.texto}>
              Información recopilada a través de los servicios de este dominio.
            </p>
            <p className={classes2.texto}>Contenido generado por el usuario:</p>
            <p className={classes2.texto}>
              Le ofrecemos la posibilidad de publicar contenido que otros
              usuarios puedan leer (por ejemplo, comentarios o reseñas).
            </p>
            <p className={classes2.texto}>
              Cualquiera puede leer, recopilar y usar cualquier información
              personal que acompañe a sus publicaciones.
            </p>
            <p className={classes2.texto}>
              No tenemos que publicar ninguno de sus contenidos.
            </p>
            <p className={classes2.texto}>
              Si la ley nos exige eliminar, eliminar o editar su información
              personal, cumpliremos en la medida requerida.
            </p>
            <h3 className={classes2.titulo}>
              Información recopilada automáticamente
            </h3>
            <p className={classes2.texto}>
              Con las tecnologías de seguimiento en su navegador y aplicaciones
              móviles:
            </p>
            <p className={classes2.texto}>
              Estas tecnologías incluyen cookies, balizas web, etiquetas y
              scripts, kits de desarrollo de software (o SDK) y más.
            </p>
            <p className={classes2.texto}>
              Rastreamos y almacenamos datos sobre cómo visita y usa los
              Servicios de Times, particularmente a través de nuestros sitios
              web y aplicaciones.
            </p>
            <p className={classes2.texto}>
              Los artículos que registramos incluyen:
            </p>
            <li className={classes2.texto}>Dirección IP</li>
            <li className={classes2.texto}>Ubicación</li>
            <li className={classes2.texto}>Sistema Operativo</li>
            <li className={classes2.texto}>Navegador del navegador</li>
            <li className={classes2.texto}>Idioma</li>
            <li className={classes2.texto}>URL de páginas visitadas</li>
            <li className={classes2.texto}>Identificadores de dispositivo</li>
            <p className={classes2.texto}>
              Combinamos estos datos con otras informaciones que recopilamos
              sobre usted haciendo un seguimiento a través de cookies (una
              cookie se refiere a un fichero que es enviado con la finalidad de
              solicitar permiso para almacenarse en su ordenador, al aceptar
              dicho fichero se crea y la cookie sirve entonces para tener
              información respecto al tráfico web, y también facilita las
              futuras visitas a una web recurrente.
            </p>
            <p className={classes2.texto}>
              Otra función que tienen las cookies es que con ellas las web
              pueden reconocerte individualmente y por tanto brindarte el mejor
              servicio personalizado de su web.
            </p>
            <p className={classes2.texto}>
              Nuestro sitio web emplea las cookies para poder identificar las
              páginas que son visitadas y su frecuencia.
            </p>
            <p className={classes2.texto}>
              Esta información es empleada únicamente para análisis estadístico
              y después la información se elimina de forma permanente.
            </p>
            <p className={classes2.texto}>
              Usted puede eliminar las cookies en cualquier momento desde su
              ordenador.
            </p>
            <p className={classes2.texto}>
              Sin embargo las cookies ayudan a proporcionar un mejor servicio de
              los sitios web, estás no dan acceso a información de su ordenador
              ni de usted, a menos de que usted así lo quiera y la proporcione
              directamente.
            </p>
            <p className={classes2.texto}>
              Usted puede aceptar o negar el uso de cookies, sin embargo la
              mayoría de navegadores aceptan cookies automáticamente pues sirve
              para tener un mejor servicio web. También usted puede cambiar la
              configuración de su ordenador para declinar las cookies.
            </p>
            <p className={classes2.texto}>
              Si se declinan es posible que no pueda utilizar algunos de
              nuestros servicios.)
            </p>
            <h3 className={classes2.titulo}>
              ¿Qué hacemos con la información que recopilamos sobre usted?
            </h3>
            <p className={classes2.texto}>
              Usamos toda la información recopilada para brindarle un mejor
              servicio y personalizar su experiencia.
            </p>
            <p className={classes2.texto}>
              Análisis Analizamos los datos sobre el comportamiento de los
              usuarios esto nos ayuda a tomar mejores decisiones comerciales y
              de marketing.
            </p>
            <p className={classes2.texto}>
              También analizamos características sobre nuestros lectores, que a
              veces compartimos en conjunto con los anunciantes.
            </p>
            <p className={classes2.texto}>
              Google Analytics es uno de los proveedores de análisis que
              utilizamos.
            </p>
            <p className={classes2.texto}>
              Puede averiguar cómo utiliza Google Analytics los datos y cómo
              darse de baja de Google Analytics.
            </p>
            <p className={classes2.texto}>
              Permitimos publicidad personalizada de terceros, recopilamos datos
              y trabajamos con terceros para mostrarle anuncios personalizados.
            </p>
            <p className={classes2.texto}>
              Estos datos provienen de tecnologías de seguimiento de anuncios
              (por ejemplo, cookies) , la información que proporciona (por
              ejemplo, su dirección de correo electrónico), datos recopilados a
              medida que utiliza los servicios de este dominio (por ejemplo, su
              historial de lectura) , datos de anunciantes o vendedores de
              publicidad (por ejemplo, datos demográficos) y cualquier cosa
              inferida de cualquiera de esta información. No tenemos acceso ni
              control sobre la información recopilada por terceros.
            </p>

            <p className={classes2.texto}>
              Solo usamos o compartimos esta información de una manera que no
              revela su identidad.La información recolectada sirve para que se
              te muestren anuncios personalizados al navegar por nuestra web,
              basados en tus intereses y comportamiento dentro de este sitio
              web.
            </p>
            <p className={classes2.texto}>
              Por ejemplo, usamos Google para publicar anuncios en las páginas
              de este dominio.
            </p>
            <p className={classes2.texto}>
              Google utiliza cookies de doble clic o identificadores únicos de
              dispositivos, en combinación con sus propios datos, para mostrarle
              anuncios basados en su visita a este dominio y otros sitios.
            </p>
            <p className={classes2.texto}>
              Puede obtener más información sobre el uso de las cookies de
              Google visitando la política de privacidad de Google relacionada y
              dándose de baja. El accesso a nuestro sitio web no se verá
              afectado por tus decisiones de no aceptar las cookies de terceras
              partes.
            </p>
            <h3 className={classes2.titulo}>
              ¿Con quién compartimos la información recopilada?
            </h3>
            <p className={classes2.texto}>
              La información de los usuarios de este dominio no es compartida de
              manera directa con ninguna persona o empresa a excepción de los
              servicios de terceros mencionados arriba.
            </p>
            <p className={classes2.texto}>
              Podemos preservar o compartir las informaciones recopiladas si la
              ley exige que lo hagamos o para la protección de nuestros derechos
              y uso de propiedad.
            </p>
            <h3 className={classes2.titulo}>¿Cuáles son tus derechos?</h3>
            <p className={classes2.texto}>
              Darse de baja Usted puede darse de baja en cualquier momento de
              todos los servicios de suscripción expuestos en este dominio.
            </p>
            <p className={classes2.texto}>
              Email: Para darse de baja de nuestra lista de email solo tienes
              que ir a la parte final de cada email y pinchar en ‘Dejar de
              recibir’ También puede enviarnos un correo electrónico a
              ahurocha@gmail.com.
            </p>
            <p className={classes2.texto}>
              Push: Puedes darse de baja de las notificaciones push en cualquier
              momento ajustando la configuración de su navegador.
            </p>
            <p className={classes2.texto}>
              Mensajes de texto: Puedes optar por no recibir alertas de texto en
              cualquier momento respondiendo “STOP” o cualquier palabra clave
              alternativa que hayamos compartido con usted.
            </p>
            <p className={classes2.texto}>
              Completamos cualquier solicitud de exclusión tan rápido como
              podemos.
            </p>
            <p className={classes2.texto}>
              Esta solicitud de exclusión no nos prohibirá enviarle avisos
              importantes que no sean de marketing.
            </p>
            <h3 className={classes2.titulo}>
              ¿Cuánto tiempo se retiene los datos?
            </h3>
            <p className={classes2.texto}>
              Por lo general no recopilamos ningún tipo de dato confidencial,
              todos los demás recopilados se retienen durante el tiempo que sea
              necesario y permitido de conformidad con la ley aplicada.
            </p>
            <h3 className={classes2.titulo}>
              ¿Cómo protegemos tu información?
            </h3>
            <p className={classes2.texto}>
              Protegemos su información personal con una serie de salvaguardas
              organizativas, tecnológicas y físicas, pero no podemos garantizar
              su seguridad absoluta.
            </p>
            <p className={classes2.texto}>
              Si tiene razones para creer que su interacción con nosotros ya no
              es segura, notifíquenos de inmediato.
            </p>
            <h3 className={classes2.titulo}>¿Existen pautas para los niños?</h3>
            <p className={classes2.texto}>
              Los servicios de este dominio están destinados a un público
              general y no están dirigidos a niños menores de (13) años de edad.
            </p>
            <p className={classes2.texto}>
              No recopilamos intencionalmente información personal según lo
              define la Ley de Protección de la Privacidad de los Niños.
            </p>
            <p className={classes2.texto}>
              Si es padre o tutor y cree que hemos recopilado información de su
              hijo de una manera no permitida por la ley, contáctenos en
              ahurocha@gmail.com. Eliminaremos los datos de manera inmediata.
            </p>
            <h3 className={classes2.titulo}> Enlaces a servicios terceros</h3>
            <p className={classes2.texto}>
              Algunos servicios de este dominio contienen enlaces a sitios web
              de terceros, recursos, proveedores y anunciantes. Estos terceros
              no son servicios de este dominio.
            </p>
            <p className={classes2.texto}>
              No controlamos (y no somos responsables) el contenido de terceros
              o las prácticas de privacidad.
            </p>
            <p className={classes2.texto}>
              Los datos personales que les proporcione no están cubiertos por
              esta Política de privacidad.
            </p>
            <h3 className={classes2.titulo}>Cambios en las políticas</h3>
            <p className={classes2.texto}>
              Periódicamente actualizamos esta Política de privacidad.
            </p>
            <p className={classes2.texto}>
              Publicaremos cualquier cambio en esta página actualizando esta
              política.
            </p>
            <p className={classes2.texto}>
              Si hacemos un cambio significativo o material en la forma en que
              recopilamos, usamos o compartimos su información personal, le
              notificaremos al menos 30 días antes de que los cambios entren en
              vigencia.
            </p>
            <h3 className={classes2.titulo}>¿Cómo puedes contactarnos?</h3>
            <p className={classes2.texto}>
              Si tienes alguna pregunta o petición, envíenos un correo
              electrónico ahurocha@gmail.com. Haremos lo posible para
              responderle lo más brevemente posible.
            </p>
            <p className={classes2.texto}>
              Este dominio se reserva el derecho de cambiar los términos de la
              presente Política de Privacidad en cualquier momento.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
