import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Room from "@material-ui/icons/Room";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "./Modal";
import Galera from "./Galera";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,

    border: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.blanco,
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco,
  },
  fundoContenedorGrids: {
    backgroundColor: theme.palette.primary.main,
  },
  topi: {
    backgroundColor: theme.palette.text.primary,
  },
  ColorMenu: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
  },
  letras: {
    color: theme.palette.primary.blanco,
    fontSize: "120%",
  },

  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%",
  },
  Ads: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

function CardFull({ job }) {
  const classes = useStyles();

  let location = job.location;
  location = location.replace(/-/g, " ");

  const Free = () => {
    return (
      <div
        style={{
          padding: "2px",
          width: "auto",
          height: "25px",
          backgroundColor: "#FCE057",
          textAlign: "center",
          alignContent: "center",
        }}
      >
        <span style={{ color: "black", fontWeight: "bold" }}>{job.date}</span>
      </div>
    );
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography
          variant="h5"
          component="div"
          style={{ marginBottom: "10px" }}
        >
          {job.title}
        </Typography>
        <Typography>{`${job.description.substring(0, 100) +
          "..."}`}</Typography>

        <div style={{ display: "flex", marginTop: "10px" }}>
          <Room />
          <p> {location}</p>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Modal job={job} />

          <Galera />
        </div>
      </CardContent>
      <Free />
    </Card>
  );
}

export default CardFull;
