import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks";
import Footer from "components/Footer/Footer.js";
import JobsContainer from "./JobsContainer";
import listaCursosPageStyle from "assets/jss/material-kit-pro-react/views/listaCursosPageStyle.js";
import { useParams } from "react-router";

const useStyles = makeStyles(listaCursosPageStyle);

export default function Companies() {
  const classes = useStyles();
  const { name } = useParams();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div>
      <Header
        color="danger"
        brand={capitalizeFirstLetter(name)}
        links={<HeaderLinks dropdownHoverColor="danger" />}
        fixed
      />

      <div className={classes.main}>
        <div className={classes.container}>
          <JobsContainer company={name} />
        </div>
      </div>

      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="/en/privacy-policies/" className={classes.block}>
                    Privacy Policies
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/en/contact" className={classes.block}>
                    Contact
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
      />
    </div>
  );
}
