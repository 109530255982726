/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import CursosContainer from "./Sections/CursosContainer";

// sections for this page

import listaCursosPageStyle from "assets/jss/material-kit-pro-react/views/listaCursosPageStyle.js";

const useStyles = makeStyles(listaCursosPageStyle);

export default function BlogPostsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        color="danger"
        brand="Cursos"
        links={<HeaderLinks dropdownHoverColor="danger" />}
        fixed
      />

      <div className={classes.main}>
        <div className={classes.container}>
          <CursosContainer />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="/politicas-de-privacidad" className={classes.block}>
                    Políticas de Privacidad
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/aviso-legal" className={classes.block}>
                    Aviso Legal
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/" className={classes.block}>
                    Inicio
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/derechos" className={classes.block}>
                    Derechos
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
      />
    </div>
  );
}
