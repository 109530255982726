import React from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import a from "../../assets/img/a.jpeg";
import b from "../../assets/img/b.jpg";
import c from "../../assets/img/c.jpg";
import d from "../../assets/img/d.jpg";
import e from "../../assets/img/e.jpg";
import f from "../../assets/img/f.jpg";
import g from "../../assets/img/g.jpeg";
import h from "../../assets/img/h.jpg";
import i from "../../assets/img/i.jpeg";
import j from "../../assets/img/j.jpeg";
import k from "../../assets/img/k.jpg";
import l from "../../assets/img/l.png";
import m from "../../assets/img/m.jpg";
import n from "../../assets/img/n.jpeg";
import o from "../../assets/img/o.jpg";
import p from "../../assets/img/p.jpg";
import q from "../../assets/img/q.jpg";
import r from "../../assets/img/r.jpg";
import t from "../../assets/img/t.jpg";
import w from "../../assets/img/w.jpg";
import y from "../../assets/img/y.jpg";
import z from "../../assets/img/z.jpg";

import aa from "../../assets/img/aa.jpg";
import bb from "../../assets/img/bb.jpg";
import cc from "../../assets/img/cc.jpg";
import dd from "../../assets/img/dd.jpg";
import ee from "../../assets/img/ee.jpg";
import ff from "../../assets/img/ff.jpg";
import gg from "../../assets/img/gg.jpg";
import hh from "../../assets/img/hh.jpg";
import ii from "../../assets/img/ii.jpg";
import jj from "../../assets/img/jj.jpg";
import kk from "../../assets/img/kk.jpg";
import ll from "../../assets/img/ll.jpg";
import mm from "../../assets/img/mm.jpg";
import nn from "../../assets/img/nn.jpg";
import oo from "../../assets/img/oo.jpg";
import pp from "../../assets/img/pp.jpg";
import qq from "../../assets/img/qq.jpg";
import rr from "../../assets/img/rr.jpg";
import tt from "../../assets/img/tt.jpg";
import ww from "../../assets/img/ww.jpg";
import yy from "../../assets/img/yy.jpg";
import zz from "../../assets/img/zz.jpg";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 30,
    height: 30,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function BadgeAvatars() {
  const classes = useStyles();

  const ind = {
    0: a,
    1: b,
    2: c,
    3: d,
    4: e,
    5: f,
    6: g,
    7: h,
    8: i,
    9: j,
    10: k,
    11: l,
    12: m,
    13: n,
    14: o,
    15: p,
    16: q,
    17: r,
    18: t,
    19: w,
    20: y,
    21: z,
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    31: "",
    32: "",
    33: "",
    34: "",
  };
  const ind2 = {
    0: aa,
    1: bb,
    2: cc,
    3: dd,
    4: ee,
    5: ff,
    6: gg,
    7: hh,
    8: ii,
    9: jj,
    10: kk,
    11: ll,
    12: mm,
    13: nn,
    14: oo,
    15: pp,
    16: qq,
    17: rr,
    18: tt,
    19: ww,
    20: yy,
    21: zz,
  };

  const urlImg = Math.round(Math.random() * 34);
  const urlImg2 = Math.round(Math.random() * 20 + 1);
  const urlImg3 = Math.round(Math.random() * 21);
  const urlImg4 = Math.round(Math.random() * 21);

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
      >
        <AvatarGroup max={4}>
          <SmallAvatar alt="Reme" src={ind[urlImg]} />
          <SmallAvatar alt="Travis Howard" src={ind2[urlImg2]} />
          <SmallAvatar alt="Pepe" src={ind[urlImg3]} />

          <SmallAvatar
            alt="Lola"
            className={classes.SmallAv}
            src={ind2[urlImg4]}
          />
        </AvatarGroup>
      </StyledBadge>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      ></Badge>
    </div>
  );
}
