import React, { useEffect, useState, useCallback } from "react";

import Modal from "../Sections/Modal";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Galera from "../../ComponentesExtras/Galera";
import Grid from "@material-ui/core/Grid";
import InputStyled from "../../ComponentesExtras/Input";
import AdsGoogle from "../../../AdsGoogle";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Helmet } from "react-helmet";
import axios from "axios";
import getSearch from "./GetSeach";
import Alert from "./Alert";

import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  headerColor,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
  dangerColor,
} from "../../../assets/jss/material-kit-pro-react";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: grayColor[0],
    padding: theme.spacing(8, 0, 6),
  },
  textHeroContent: {
    color: whiteColor,
    fontSize: "105%",
    fontWeight: "bold",
  },
  textHeroContentDestaque: {
    color: headerColor,
  },
  heroTittle: {
    color: whiteColor,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "450px",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: grayColor[3],
    color: whiteColor,
  },
  footer: {
    backgroundColor: grayColor,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco,
  },
  fundoContenedorGrids: {
    backgroundColor: grayColor,
  },

  ColorMenu: {
    backgroundColor: headerColor,
    color: whiteColor,
  },

  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%",
  },
  Ads: {
    color: blackColor,
    backgroundColor: grayColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "none",
    textAlign: "center",
  },
  menuText: {
    marginTop: "10px",
    fontSize: "18px",
    textTransform: "none",
  },
  noEmail: {
    color: grayColor[3],
    borderColor: grayColor[3],
    marginRight: "10px",

    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5],
    },
    inscritos: {
      fontSize: "20px",
    },
  },
  inscribirme: {
    marginTop: "10px",

    color: headerColor,
    borderColor: headerColor,
    "&:hover,&:focus": {
      color: whiteColor,
      borderColor: whiteColor,
    },
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  BotonCargarMas: {
    marginBottom: "100px",
    backgroundColor: dangerColor[0],
    borderColor: dangerColor[0],

    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.2)",

    "&:hover": {
      color: dangerColor[0],
      backgroundColor: dangerColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)",
    },
    "&:focus": {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[3]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[3]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[3]) +
        ", 0.2)",
    },
  },
  containerLoad: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  containerInput: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  botonBuscar: {
    width: "150px",
    backgroundColor: dangerColor[0],
    borderColor: dangerColor[0],

    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: dangerColor[0],
      backgroundColor: dangerColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)",
    },
  },
  notFound: {
    padding: "20px",
  },
  listAlert: {
    padding: "20px",
    width: "290px",
  },
}));
export default function CursosContainer() {
  const classes = useStyles();

  let { busqueda } = useParams();

  const history = useHistory();

  let contadorCursos = 0;
  let j = 0;

  const [cursos, setCursos] = useState([]);
  const [cargarMas, setCargarMas] = useState(9);
  const [input, setInput] = useState("");
  const [empty, setEmpty] = useState(false);
  const [alert, setAlert] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const carregarCursos = useCallback(async () => {
    const url = `https://api.hmcursos.com/api/search/${busqueda}`;
    const solve = await axios.get(url);
    if (solve.data.length === 0) {
      setNotFound(true);
    }

    setCursos(solve.data);
  }, [setCursos, busqueda]);

  const handleBuscar = async () => {
    //não pode estar vazio
    setNotFound(false);
    if (input === "") {
      setEmpty(true);
    } else {
      //Save Search
      await axios.post(
        "https://api.hmcursos.com/api/search/save",
        { search: input }
      );

      history.push(`/cursos/${input}`);

      setAlert(true);

      setTimeout(() => {
        setAlert(false);
      }, 2000);
    }
  };

  const handleLoad = () => {
    setCargarMas(cargarMas + 9);
  };
  const handleInput = (e) => {
    setInput(getSearch(e.target.value));
    setEmpty(false);
  };

  useEffect(() => {
    carregarCursos();
  }, [carregarCursos]);

  const renderCurso = (curso) => {
    return (
      <Grid item xs={12} sm={6} md={6} key={curso._id}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.cardMedia}
            image={curso.image_url}
            title={curso.name}
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {`${curso.name}`}
            </Typography>
            <Typography>{`${
              curso.description.slice(0, 80) + "..."
            }`}</Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <Modal curso={curso} />

              <Galera />
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderCardAds = () => {
    return (
      <Grid item xs={12} sm={6} md={5}>
        <Typography align="center" className={classes.Ads}>
          <AdsGoogle block={2} />
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      <Helmet>
        <title>HM Cursos - Lista de Cursos</title>
      </Helmet>

      <main className={classes.fundoContenedorGrids}>
        <div>
          {/*HEADER DOWN*/}
          <Container maxWidth="md">
            <br></br>

            <div className={classes.containerInput}>
              <Typography align="left" variant="h6" className={classes.Ads}>
                Hola, escribe abajo el curso al que buscas...
              </Typography>

              <div>
                <InputStyled
                  onChange={handleInput}
                  placeholder="Marketing, Excel..."
                />
              </div>
              {empty ? (
                <p style={{ color: dangerColor[0] }}>
                  Por favor, escribe lo que buscas.
                </p>
              ) : null}
              {alert ? (
                <div className={classes.listAlert}>
                  <Alert message={"Resultados listados abajo"} />
                </div>
              ) : null}

              <Button
                variant="outlined"
                className={classes.botonBuscar}
                onClick={handleBuscar}
                disabled={alert}
              >
                Buscar
              </Button>
            </div>
            {notFound ? (
              <div className={classes.notFound}>
                <h4 style={{ textAlign: "center" }}>
                  No hemos encontrado cursos con tu búsqueda
                </h4>
                <li>Revisa si has escrito bien la búsqueda.</li>
                <li>Prueba buscar con una sola palabra.</li>
                <li>Cualquier duda contacta con nosotros.</li>
              </div>
            ) : null}

            {/*----------------------- HEADER ADS ----------------------*/}
            <Typography align="center" className={classes.Ads}>
              <br></br>
              <AdsGoogle />
            </Typography>
          </Container>
        </div>
        {/*----------------------- CARDS ----------------------*/}

        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4} className={classes.cardGrid}>
            {cursos.map((curso) => {
              if (contadorCursos < cargarMas) {
                contadorCursos++;
                if (j < 6) {
                  j++;
                  return renderCurso(curso);
                } else {
                  j = 0;
                  return renderCardAds();
                }
              } else {
                return null;
              }
            })}
          </Grid>
        </Container>
      </main>
      {contadorCursos !== cursos.length ? (
        <div className={classes.containerLoad}>
          <Button
            variant="outlined"
            onClick={handleLoad}
            className={classes.BotonCargarMas}
          >
            Cargar más...
          </Button>
        </div>
      ) : null}
    </>
  );
}
