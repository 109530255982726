import React from "react";

import { isMobile } from "react-device-detect";

import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazyload";

// import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CardFull from "./CardFull";

import Ads from "../../AdsGoogle";

import {
  whiteColor,
  hexToRgb,
  dangerColor,
} from "../../assets/jss/material-kit-pro-react";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    // flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.blanco,
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco,
  },
  fundoContenedorGrids: {
    backgroundColor: theme.palette.primary.main,
  },
  topi: {
    backgroundColor: theme.palette.text.primary,
  },
  ColorMenu: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
  },
  letras: {
    color: theme.palette.primary.blanco,
    fontSize: "120%",
  },
  recibir: {
    color: theme.palette.primary.blanco,
  },
  bb: {
    color: theme.palette.secondary.light,
  },
  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%",
  },
  Ads: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  containerLoad: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  BotonCargarMas: {
    backgroundColor: dangerColor[0],
    borderColor: dangerColor[0],

    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.2)",

    "&:hover": {
      color: dangerColor[0],
      backgroundColor: "white",
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)",
    },
    "&:focus": {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[3]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[3]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[3]) +
        ", 0.2)",
    },
  },
}));

export default function CardsWithJobs({ jobs }) {
  let counter = 0;
  let settingAds = 6;
  let stopAds = 0;

  const classes = useStyles();

  if (jobs.length > 0)
    if (isMobile) {
      settingAds = 3;
    }
  return (
    <>
      <main>
        <div>
          {/*ADS*/}

          <br></br>

          <Ads />

          {/*ADS*/}
        </div>
        <Grid container spacing={4} className={classes.cardGrid}>
          {jobs.map((job) => {
            if (counter < settingAds || stopAds === 2) {
              counter++;
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={Math.random() * 10 + 2334244 * Math.random() * 100}
                >
                  <LazyLoad offset={100}>
                    <CardFull job={job} />
                  </LazyLoad>
                </Grid>
              );
            } else {
              counter = 0;
              stopAds++;
              return (
                <Container
                  maxWidth="md"
                  key={Math.random() * 10 + 433334 * Math.random() * 100}
                >
                  <LazyLoad offset={35}>
                    <Ads block={2} />
                  </LazyLoad>
                </Container>
              );
            }
          })}
        </Grid>
      </main>
    </>
  );
}
