import React, { Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";

import { Helmet } from "react-helmet";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import { DonutSmall } from "@material-ui/icons";
import { blackColor, headerColor } from "assets/jss/material-kit-pro-react";
import ReactHtmlParser from "react-html-parser";

import articles from '../articles.json'

const useStyles = makeStyles(blogsStyle);
const useStyles2 = makeStyles({
  ajuste: {
    marginTop: "-50px",
  },
  leer: {
    color: blackColor,
    marginLeft: "5px",
    fontWeight: "bold",
    textDecoration: "underline",
    "&:hover": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
});

export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  const classes2 = useStyles2();

  if (articles.length === 0) {
    return (
      <div className={classes2.loading}>
        <DonutSmall style={{ marginRight: "5px" }} />
        Cargando...
      </div>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>HM Cursos - Blog</title>
      </Helmet>
      <div className="cd-section" {...rest}>
        {/* Blogs 3 START */}
        <div className={classes.blog}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={10}
                md={10}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                {articles.map((item) => {
                  return (
                    <Card plain blog className={classes.card} key={item._id}>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={4}>
                          <CardHeader image plain>
                            <a href={`https://hmcursos.com/blog/${item.id}`}>
                              <img
                                src={item.url_image}
                                alt={item.title}
                                style={{ width: "300px", height: "250px" }}
                              />
                            </a>
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${item.url_image})`,
                                opacity: "1",
                                width: "310px",
                                height: "260px",
                              }}
                            />
                          </CardHeader>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8}>
                          <Info>
                            <h6 className={classes.cardCategory}>
                              {item.category}
                            </h6>
                          </Info>
                          <h3 className={classes.cardTitle}>
                            <a href={`https://hmcursos.com/blog/${item.id}`}>
                              {item.title}
                            </a>
                          </h3>
                          <p className={classes.description}>
                            {ReactHtmlParser(
                              item.content.slice(0, 120) + "..."
                            )}
                            <a
                              href={`https://hmcursos.com/blog/${item.id}`}
                              className={classes2.leer}
                            >
                              Leer ahora
                            </a>
                          </p>
                          <p className={classes.author}>
                            por <b>Hugo Rocha</b>
                          </p>
                        </GridItem>
                      </GridContainer>
                    </Card>
                  );
                })}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
