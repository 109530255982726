import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .content-button-red,
  .content-button-yellow {
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.6875rem;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid transparent;
    text-align: center;
    color: #fff !important;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
    &:focus {
      color: #FFF;
      text-decoration: none;
    }
  }
  .content-button-red {
    background: #e50914;
    &:hover {
      background: rgba(229, 9, 20, 0.75);
    }
  }
  .content-button-yellow {
    background: #da8c07;
    &:hover {
      background: rgba(218, 140, 7, 0.75);
    }
  }
`